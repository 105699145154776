import { YStack, YStackProps, Spinner } from 'tamagui'

export type PageLoadingProps = YStackProps & { scroll?: boolean }

export const PageLoading = ({ scroll, ...rest }: PageLoadingProps) => {
  return (
    <YStack flex={1} justifyContent="center" alignItems="center" {...rest}>
      <Spinner color="$primary.500" size="large" />
    </YStack>
  )
}

import { BountyFeature, BountyPlanCode, BountyFeatureCode } from '@bounty/types'

export const BOUNTY_FEATURES: Array<BountyFeature> = [
  {
    name: 'Tax compliance and management',
    code: 'TAX_COMPLIANCE_AND_MANAGEMENT',
    availableOn: ['V2_ENTERPRISE_PLAN', 'V2_PRO_PLAN', 'V2_BASIC_PLAN'],
    description:
      'Bounty automatically manages tax documents for all creators who earn over the federal annual requirement to claim income.',
    displayInPricing: true,
  },
  {
    name: 'FTC compliance',
    code: 'FTC_COMPLIANCE',
    availableOn: ['V2_ENTERPRISE_PLAN', 'V2_PRO_PLAN', 'V2_BASIC_PLAN'],
    description:
      "Bounty ensures all posts are compliant with FTC policies related to social media promotions before creators are able to withdraw funds, meaning until they are fully compliant, the post isn't a paid promotion.",
    displayInPricing: true,
  },
  {
    name: 'UGC at checkout',
    code: 'UGC_AUTOMATION',
    availableOn: ['V2_ENTERPRISE_PLAN', 'V2_PRO_PLAN', 'V2_BASIC_PLAN'],
    description:
      'Display Bounty to your customers on the Order Confirmation page, prompting them to "post on TikTok and get paid" when their order arrives.',
    displayInPricing: true,
  },
  {
    name: 'Customer activation flows',
    code: 'CUSTOMER_ACTIVATION_FLOWS',
    availableOn: ['V2_ENTERPRISE_PLAN', 'V2_PRO_PLAN', 'V2_BASIC_PLAN'],
    description:
      "Send automated email flows to customers who've already purchased your product, inviting them to post and get paid.",
    displayInPricing: true,
  },
  {
    name: 'Invite your own creators',
    code: 'INVITE_MANAGEMENT',
    availableOn: ['V2_ENTERPRISE_PLAN', 'V2_PRO_PLAN', 'V2_BASIC_PLAN'],
    description:
      'CSV uploads to send invite and manage creators you already work with inside of Bounty',
    displayInPricing: true,
  },
  {
    name: 'Product gifting',
    code: 'GIFTING',
    availableOn: ['V2_ENTERPRISE_PLAN', 'V2_PRO_PLAN', 'V2_BASIC_PLAN'],
    description:
      "Configure gifts and offers you can share with anyone. Bounty automates order creation in Shopify, with customizable notes and Shopify order tags, and tracks recipient's posts on social media for brand mentions.",
    displayInPricing: true,
  },
  {
    name: 'Fraud detection',
    code: 'FRAUD_DETECTION',
    availableOn: ['V2_ENTERPRISE_PLAN', 'V2_PRO_PLAN', 'V2_BASIC_PLAN'],
    description:
      'Automatic detection and disapproval of posts with inorganic engagement patterns.',
    displayInPricing: true,
  },
  {
    name: 'Listing in brand directory',
    code: 'BRAND_DIRECTORY',
    availableOn: ['V2_ENTERPRISE_PLAN', 'V2_PRO_PLAN', 'V2_BASIC_PLAN'],
    description:
      'Get your store listed in the brand directory on our website and mobile app, exposing you to tens of thousands of creators ready to create content',
    displayInPricing: true,
  },
  {
    name: 'Spark ads management',
    code: 'SPARK_ADS_MANAGER',
    availableOn: ['V2_ENTERPRISE_PLAN', 'V2_PRO_PLAN', 'V1_BOUNTY_PLAN'],
    description:
      'Automate spark code requests to creators. Codes delivered directly to your brand portal.',
    displayInPricing: true,
  },
  {
    name: 'Creator network',
    code: 'BOUNTY_CREATOR_NETWORK',
    availableOn: ['V2_ENTERPRISE_PLAN', 'V2_PRO_PLAN', 'V1_BOUNTY_PLAN'],
    description:
      "Tap Bounty's network of creators for the perfect partners with gifts and special offers.",
    displayInPricing: true,
  },
  {
    name: 'UGC on product page',
    code: 'PRODUCT_PAGE_WIDGET',
    availableOn: ['V2_ENTERPRISE_PLAN', 'V2_PRO_PLAN', 'V1_BOUNTY_PLAN'],
    description:
      'Display Bounty to your visitors on your Product Pages, prompting them to "post on TikTok and get paid" after they complete their order.',
    displayInPricing: true,
  },
  {
    name: 'Remove branding',
    code: 'NO_BRANDING_ON_WIDGET',
    availableOn: ['V2_ENTERPRISE_PLAN', 'V2_PRO_PLAN'],
    description:
      'Disable the "powered by Bounty" branding on your user experiences.',
    displayInPricing: true,
  },
  {
    name: 'Prospects',
    code: 'PROSPECTS',
    availableOn: ['V2_ENTERPRISE_PLAN', 'V2_PRO_PLAN', 'V1_BOUNTY_PLAN'],
    description:
      'We find micro and nano creators from outside the Bounty network that could be a great fit for your brand and display them in your dashboard for approval. Once you approve a creator Bounty automates outreach, order creation and social listening if the prospect redeems their gift. Think of it like an Influencer seeding agency, but without the crazy monthly retainer.',
    displayInPricing: true,
  },
  {
    name: 'Promote offers in brand directory',
    code: 'OFFERS_IN_BRAND_DIRECTORY',
    availableOn: ['V2_ENTERPRISE_PLAN'],
    description:
      'Display your marketing campaigns and offers across the Bounty brand directory on our website and mobile app.',
    displayInPricing: true,
  },
  {
    name: 'Priority support',
    code: 'DEDICATED_CUSTOMER_SUCCESS_MANAGER',
    availableOn: ['V2_ENTERPRISE_PLAN'],
    description:
      'Your support requests go to the front of the line for priority response.',
    displayInPricing: true,
  },
  {
    name: 'Custom branded hashtag',
    code: 'CUSTOM_BRANDED_HASHTAG',
    availableOn: ['V2_ENTERPRISE_PLAN'],
    description:
      "By default, Bounty requires creators to use #bounty in their posts. Custom branded hashtags allows you to provide any branded hashtag you'd like instead of #bounty ex: #XYZBrandPartner. A more common requirement for larger brands with a legal team and stricter partnership requirements.",
    displayInPricing: true,
  },
  {
    name: 'Custom inspiration videos',
    code: 'CUSTOM_INSPIRATION_VIDEOS',
    availableOn: ['V2_ENTERPRISE_PLAN'],
    description:
      'Upload your own custom inspiration or instructional videos to show to creators before they make content for your brand.',
    displayInPricing: true,
  },
  {
    name: 'Dedicated slack channel',
    code: 'DEDICATED_SLACK',
    availableOn: ['V2_ENTERPRISE_PLAN'],
    description:
      "Optionally, get a dedicated Slack Channel with the Bounty team. We're always there to help you brainstorm, troubleshoot, test new features and leverage Bounty more effectively alongside all your other marketing efforts!",
    displayInPricing: true,
  },
  {
    name: 'Account manager and custom onboarding',
    code: 'ONBOARDING_IMPLEMENTATION_MANAGER',
    availableOn: ['V2_ENTERPRISE_PLAN'],
    description:
      "A dedicated account manager to help onboard your brand, execute campaigns, activate new features, and make sure you're getting the absolute best performance on Bounty.",
    displayInPricing: true,
  },
  {
    name: 'Automated order SMS flow',
    code: 'AUTOMATED_ORDER_SMS_FLOW',
    availableOn: ['V2_ENTERPRISE_PLAN', 'V2_PRO_PLAN', 'V2_BASIC_PLAN'],
    description: '',
    displayInPricing: false,
  },
  {
    name: 'Post purchase survey integration',
    code: 'POST_PURCHASE_SURVEY_INTEGRATION',
    availableOn: ['V2_ENTERPRISE_PLAN'],
    description: '',
    displayInPricing: false,
  },
]

export const customFeatures = [
  {
    name: 'Retail Bounties',
    code: 'RETAIL_BOUNTIES',
    availableOn: ['V2_ENTERPRISE_PLAN'],
    description: '',
    displayInPricing: false,
  },
  {
    name: 'Instagram',
    code: 'INSTAGRAM',
    availableOn: ['V2_ENTERPRISE_PLAN'],
    description: '',
    displayInPricing: false,
  },
]

export const isFeatureAvailableForPlan =
  (code: BountyFeatureCode) => (plan: BountyPlanCode) => {
    const maybeFeature = BOUNTY_FEATURES.find((b) => b.code === code)

    if (!maybeFeature) {
      throw new Error('Feature not found for code: ' + code)
    }
    return maybeFeature.availableOn.includes(plan)
  }
